<template>
  <div class="bg-white h-full">
    <div class="divide-y-2 divide-blue-30 lg:divide-none flex flex-col lg:flex-row">
      <section class="lg:w-2/5 p-4 mb-8">
        <h1 class="text-xl font-bold mb-4">Fomulário para emissão do Termo de Compromisso de Manutenção de Sigilo e Responsabilidade</h1>
        <ul class="list-inside list-disc">
          <li>
            <span>O Termo em referência constitui documento necessário para cadastro no Sistema de Gestão Correicional - SGC</span>
          </li>
        </ul>
      </section>
      <section class="lg:w-3/5 flex flex-col items-center p-4">
        <form class="max-w-3xl w-full bg-white flex flex-col lg:grid lg:grid-cols-2 items-end gap-4" autocomplete="off">
          <h1 class="col-span-2 text-center w-full">Preencher todas as informações abaixo:</h1>
          <div 
            :class="field.width && 'col-span-2'"
            class="w-full"
            v-for="field in fields"
            :key="field.label"
          >
            <label :for="field.label" class="text-gray-500">{{ field.label }}<span class="text-red-500"> *</span></label>
            <input
              :id="field.label"
              v-if="field.label !== 'Matrícula' && field.label !== 'CPF' && field.label !== 'Telefone'"
              autocomplete="off"
              class="input"
              :type="field.type"
              :placeholder="field?.placeholder"
              v-model.lazy="field.value"
              :maxlength="field.maxCaracteres"
              :v-mask="field.mask"
            >
            <input
              :id="field.label"
              v-if="field.label == 'Matrícula'"
              autocomplete="off"
              class="input"
              :type="field.type"
              :placeholder="field?.placeholder"
              v-model.lazy="field.value"
              :maxlength="field.maxCaracteres"
              v-mask="['###.###/#', '##.###/#', '#.###/#']"
            >
            <input
              :id="field.label"
              v-if="field.label == 'CPF'"
              autocomplete="off"
              class="input"
              :type="field.type"
              :placeholder="field?.placeholder"
              v-model.lazy="field.value"
              :maxlength="field.maxCaracteres"
              v-mask="'###.###.###-##'"
            >
            <input
              :id="field.label"
              v-if="field.label == 'Telefone'"
              autocomplete="off"
              class="input"
              :type="field.type"
              :placeholder="field?.placeholder"
              v-model.lazy="field.value"
              :maxlength="field.maxCaracteres"
              v-mask="['#####-####', '(##)#####-####', '(##)####-####', '####-####']"
            >
            <!-- <select 
              v-if="field.type === 'select'"
              class="input"
              v-model="field.value"
            >
              <option
                v-for="unidade in unidades"
                :key="unidade"
                :value="unidade.sigla"
              >{{ unidade.nome }}</option>
            </select> -->
          </div>
          <div class="col-span-2 flex justify-end mt-4">
            <button class="button" @click.prevent="save">Gerar PDF</button>
          </div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import { onMounted, reactive, ref, watchEffect } from 'vue';
//import termoService from '@/services/termo.service.js';

const title = 'GOVERNO DO DISTRITO FEDERAL \n POLÍCIA MILITAR DO DISTRITO FEDERAL \n DEPARTAMENTO DE CONTROLE E CORREIÇÃO';
const indentOne = 'Todos os serviços de rede de computadores disponibilizados pelo DCC são de caráter prioritário às atividades pertinentes ao\n'
  + 'DCC, ou seja, administrativas, investigativas, de ensino, de pesquisa e de extensão.\n'
  + 'A vigência deste Termo é de um (01) ano. Nos casos dos policiais lotados no DCC ou unidades subordinadas, a renovação se\n'
  + 'dará automaticamente.';
const indentTwo = '1.  Não há garantias de disponibilidade de qualquer tipo de serviço de rede de computadores.\n'
  + '2.  O usuário é responsável por todo e qualquer conteúdo acessado na Internet, cabendo a esse utilizar os serviços e conteúdos\n'
  + '    com respeito às normas existentes.\n'
  + '3.  Todo o conteúdo armazenado pelo usuário nos computadores de mesa deste departamento é de responsabilidade exclusiva\n'
  + '    do usuário, devendo todos os documentos institucionais ser salvos no ambiente de rede que será disponibilizado ao usuário pela\n'
  + '    Seção de Tecnologia da Informação e Comunicação (STIC) / Divisão de Investigação Criminal (DIC) deste DCC.\n'
  + '4.  O DCC se reserva ao direito de bloquear/suspender o acesso aos serviços de rede de computadores a qualquer momento, para\n'
  + '    os fins que julgar necessário.\n'
  + '5.  Quaisquer danos causados a terceiros com o uso dos recursos de tecnologia e comunicação do DCC causados pelo usuário\n'
  + '    serão apurados por intermédio do processo legal.\n'
  + '6.  Contas de usuários inativas por 6 meses serão desabilitadas.\n'
  + '7.  Não é autorizado o uso de equipamentos particulares conectados à rede de computadores do DCC, sejam eles: computadores,\n'
  + '    modems, switches, access points, dispositivos para conexão sem fio (wireless/wifi), hubs, smartphones etc.\n'
  + '8.  Caberá à Divisão de Investigação Criminal a responsabilidade pela segurança da rede corporativa, incluindo os ativos e as\n'
  + '    informações contidas nos servidores deste DCC.\n';
const indentThree = '9.    A conta de acesso do usuário é de uso pessoal e intransferível.\n'
  + '10.  É dever do usuário gerenciar seus conteúdos acessados e armazenados, assim como zelar pelo equipamento e pela não\n'
  + '       divulgação de seus dados pessoais, como usuário e senha.\n'
  + '11.  São proibidas as seguintes condutas:\n'
  + '       a. Criar ou assumir falsa identidade, com ou sem autorização de outro usuário;\n'
  + '       b. Difamar, ofender, perturbar a tranquilidade alheia, ou qualquer outra ação que viole direitos de terceiros;\n'
  + '       c. Acessar, manter ou disponibilizar qualquer conteúdo ilegal que remete a discriminação, violência, pornografia, racismo ou\n'
  + '          preconceito;\n'
  + '       d. Violar direitos autorais;\n'
  + '       e. Acessar conteúdo identificado como impróprio conforme item 16 d, do presente termo.\n';
const indentThree2 = '12.   Em caso de suspeição de má utilização dos recursos disponibilizados para o usuário,\n'
  + '       os acessos desse serão restritos conforme recomendação do chefe da DIC no caso em concreto. \n'
  + '13.  O presente Termo poderá ser modificado quando este DCC julgar necessário, sendo publicadas em boletim interno as\n'
  + '       alterações.\n'
  + '14.  TODOS os usuários devem cumprir integralmente este Termo.\n';
const indentFour = '15.  Declaro ao acessar a rede corporativa do DCC, computadores, internet, e-mails corporativos que me comprometo:\n'
  + '       a. Ao me ausentar da estação de trabalho, encerrar a sessão dos sistemas, dos navegadores (browsers), dos sistemas\n'
  + '          corporativos, bloquear estação de trabalho, bem como encerrar a seção do e-mail corporativo, garantindo assim a\n'
  + '          impossibilidade de acesso indevido por terceiros;\n'
  + '       b. Não revelar, fora do âmbito profissional, fato ou informação de qualquer natureza de que tenha conhecimento por força de\n'
  + '          minhas atribuições, salvo em decorrência de decisão competente na esfera legal ou judicial;\n'
  + '       c. Manter a necessária cautela quando da exibição de dados em tela, impressora ou na gravação em meios eletrônicos, a fim\n'
  + '          de evitar que deles venham a tomar ciência pessoas não autorizadas;\n'
  + '       d. Não revelar minha senha de acesso à rede corporativa a ninguém e tomar o máximo de cuidado para que ela permaneça\n'
  + '          somente de meu conhecimento;\n'
  + '       e. Alterar minha senha, sempre que obrigatório ou que tenha suspeição de descoberta por terceiros, não usando combinações\n'
  + '          simples que possam ser facilmente descobertas;\n'
  + '       f. Respeitar as normas de segurança e restrições de sistema impostas pelos sistemas de segurança implantados neste DCC;\n'
  + '       g. Responder, em todas as instâncias, pelas consequências das ações ou omissões de minha parte que possam pôr em risco ou\n'
  + '          comprometer a exclusividade de conhecimento de minha senha ou das transações a que tenha acesso.\n'
  + '16.   Declaro ainda estar plenamente esclarecido e consciente que:\n'
  + '       a. Publicação em qualquer forma de mídia que eu fizer ou promover, a partir dos dados/informação constante do acesso ao\n'
  + '          sistema, será de minha responsabilidade, solidariamente com o veículo de comunicação para quem eu trabalho ou presto\n'
  + '          serviços, mesmo que eventuais.\n'
  + '       b. NÃO devo passar minha identificação e senha para quem quer que seja, sob pena de responsabilidade civil e funcional,\n'
  + '          pelo uso indevido da mesma. Concordo com este TERMO DE USO E RESPONSABILIDADE e que pelo uso desse serviço\n'
  + '          não usarei os recursos disponibilizados para digitar, publicar qualquer informação intencionalmente falsa e/ou difamatória,\n'
  + '          inexata, abusiva, odiosa, que possa hostilizar outras pessoas, empresas, produtos, marcas, marcas registradas, invadir a\n'
  + '          privacidade das pessoas, ou que viole qualquer lei.\n'
  + '       c. O uso de qualquer recurso tecnológico ou de comunicação institucional poderá ser monitorado a qualquer tempo, por\n'
  + '          equipes designadas para tal função que deverão relatar quaisquer descumprimentos deste termo, em documento próprio ao\n'
  + '          chefe da DIC para as providências cabíveis.\n'
  + '       d. Não é permitida a navegação aos sites pertencentes às categorias abaixo:\n'
  + '          i.     Pornográfico e de caráter sexual;\n'
  + '          ii.    Compartilhamento de arquivos (ex.: peer to peer, BitTorrent, Emule, etc.);\n'
  + '          iii.   Pornografia infantil (pedofilia);\n'
  + '          iv.    Apologia ao terrorismo e/ou às drogas;\n'
  + '          v.     De relacionamento (Facebook, Google+, twitter, instagram, Snapchat, etc.);\n'
  + '          vi.    Violência e agressividade (racismo, preconceito, etc.);\n'
  + '          vii.   Violação de direito autoral (pirataria, etc.);\n'
  + '          viii.  Áudio e vídeo, salvo com conteúdo relacionado diretamente às atividades administrativas ou profissionais;\n'
  + '          ix.    Comunicadores criptografados;\n'
  + '          x.     Conteúdo impróprio, ofensivo, ilegal, discriminatório ou similares.\n';
const indentFour2 = '          xi.    Todos os itens relacionados acima terão suas exceções se estiverem relacionados diretamente às atividades\n'
  + '                   profissionais do usuário.\n'
  + '       e. Não é permitida a troca de arquivos de vídeo ou música, bem como de quaisquer informações que estejam incluídas nas\n'
  + '           categorias acima;\n'
  + '       f. É proibida a transferência de qualquer tipo de programa, jogo, e similares, para a rede interna do DCC, à exceção de\n'
  + '           usuários com autorização específica para tal para atender sua atividade profissional;\n'
  + '       g. É proibido downloads de arquivos de extensões tipo: .exe, .mp3, .wav, .bat, .com, .sys, .scr, .mpeg, .avi, .rmvb, .dll, e de\n'
  + '           programas de entretenimento ou jogos, salvo os estritamente relacionados aos serviços inerentes à função do usuário;\n'
  + '       h. Não é permitido o acesso a programas de TV na internet ou qualquer conteúdo sob demanda (streaming);\n'
  + '       i. É proibido o uso de jogos, incluindo os da internet (online);\n'
  + '       j. O uso de e-mail corporativo não garante direito sobre este, nem confere autoridade para liberar acesso a outras pessoas,\n'
  + '           pois se constitui de informações pertencentes ao DCC;\n'
  + '       k. Qualquer problema referente ao uso dos computadores da Rede de Computadores do DCC, assim como ao uso da sua conta\n'
  + '           de e-mail corporativo do DCC, deverá imediatamente ser relatado à STIC;\n'
  + '       l. O usuário assumirá a responsabilidade por dano causado por algum procedimento de iniciativa própria de tentativa de\n'
  + '           modificação da configuração, física ou lógica, do computador e/ou rede sem a autorização expressa da STIC;\n'
  + '       m. O usuário assumirá a responsabilidade pelo dano que possa causar caso não venha a cumprir o disposto neste termo de\n'
  + '           responsabilidade.\n'
  + '       n. Estou ciente que devo conservar e atualizar imediatamente tais informações de Registro para mantê-las verdadeiras, exatas\n'
  + '           e completas.\n'
  + '       o. Para obter a senha do sistema, declaro que as informações prestadas acima são verdadeiras, exatas, atuais e completas sobre\n'
  + '           mim mesmo.\n';
const declaration = 'Declaro, sob as penas da lei, verdadeiras as informações neste ato prestadas, fazendo parte integrante dos registros\n'
  + 'e arquivos do DCC/PMDF, tendo ciência do que estabelecem os artigos 153, 154-A, 154-B, 299, 313-A, 313-B, 325 e 327 do\n'
  + 'Código PenalBrasileiro, 228, 312 e 326 do Código Penal Militar, a legislação aplicada e demais normas complementares,\n'
  + 'aquiescendo com todas as responsabilidades inerentes ao uso dos recursos tecnológicos do órgão, bem como das implicações legais\n'
  + 'decorrentes do seu uso indevido, seja qual for a circunstância, constituindo o usuário e senha disponibilizados para acesso\n'
  + '(e-mail e/ou rede corporativa), propriedade do DCC e portanto, sujeitos ao monitoramento e controle das ações realizadas no seu\n'
  + 'âmbito.\n'
  + 'Declaro ainda que, estou ciente que o DCC concede contas para acesso a rede de computadores e e-mail para utilização exclusiva\n'
  + 'do usuário, portanto, não disponibilizarei nem facilitarei o uso das minhas referidas contas para qualquer pessoa, funcionário ou\n'
  + 'não, ainda que hierarquicamente superior.\n'
  + 'Declaro, nesta data, ter ciência e estar de acordo com os procedimentos acima descritos, comprometendo-me a respeitá-los e\n'
  + 'cumprilos plena e integralmente.';
const obs = 'ESTA DECLARAÇÃO SÓ TERÁ VALIDADE APÓS ASSINADA DIGITALMENTE NO SISTEMA SEI';
export default {
  name: 'Term',
  setup() {
    const unidades = ref();
    //onMounted(() => {
    // const serviceTermo = new termoService();
    // serviceTermo.unidade()
    //   .then(data => {
    //     unidades.value = data.sort((x,y) => {
    //       let a = x.nome.toUpperCase(),
    //           b = y.nome.toUpperCase();
    //       return a ==b ? 0 : a > b ? 1 : -1;
    //     });
    //   });
    // })
    const subTitles = ['DA INSTITUIÇÃO', 'DO USUÁRIO', 'DO SIGILO'];
    const fields = reactive([
      {
        type: 'text',
        label: 'Nome',
        placeholder: 'Nome Completo',
        value: '',
        width: true,
        maxCaracteres: 35
      },
      {
        type: 'text',
        label: 'Matrícula',
        value: '',
        maxCaracteres: 10,
      },
      {
        type: 'text',
        label: 'CPF',
        value: '',
        maxCaracteres: 14,
      },
      {
        type: 'text',
        label: 'Posto/Graduação',
        value: '',
        maxCaracteres: 14
      },
      {
        type: 'date',
        label: 'Data de Nascimento',
        value: '',
        maxCaracteres: 8
      },
      {
        type: 'text',
        label: 'Unidade',
        placeholder: 'Sigla',
        value: '',
        maxCaracteres: 12
      },
      {
        type: 'text',
        label: 'Divisão',
        value: '',
        maxCaracteres: 15
      },
      {
        type: 'text',
        label: 'Seção',
        value: '',
        maxCaracteres: 15
      },
      {
        type: 'tel',
        label: 'Telefone',
        value: '',
        maxCaracteres: 14,
      },
      {
        type: 'email',
        label: 'E-mail',
        placeholder: 'Melhor e-mail pessoal',
        value: '',
        width: true,
        maxCaracteres: 35
      },
    ]);
    let dateUpdate = reactive();
    let dateCurrent = reactive();
    watchEffect(() => {
      dateUpdate = new Date(`'${fields[4].value}'`).toLocaleDateString();
    });
    const doc = jsPDF({
      unit: 'cm',
    });
    doc.addImage('pmdf.png', 'PNG', 1, 0, 3, 3.5);
    doc.setFontSize(12)
      .setLineHeightFactor(1.5)
      .setFont('times', 'bold')
      .text(title, 10, 1.8, 'center')
      .addImage('gdf.png', 'PNG', 16.5, 1, 3, 2);
    doc.rect(1.5, 3.4, 18, 1, 'F');
    doc.setTextColor(255, 255, 255)
      .text('REDE CORPORATIVA DO DEPARTAMENTO DE CONTROLE E CORREIÇÃO DA PMDF', 1.8, 4);
    doc.setFillColor(0, 0, 0)
      .rect(1.5, 8.4, 18, 1, 'FD');
    doc.setTextColor(255, 255, 255)
      .text('TERMO DE COMPROMISSO DE MANUTENÇÃO DE SIGILO E RESPONSABILIDADE', 1.8, 9);
    doc.setFontSize(10)
      .setFont('times', 'normal')
      .setTextColor(0, 0, 0)
      .text(indentOne, 1.5, 10);
    doc.text('TÍTULO I', 10, 12.5, null, null, 'center');
    doc.text(subTitles[0], 10, 13, null, null, 'center');
    doc.setFontSize(10)
      .setFont('times', 'normal')
      .text(indentTwo, 1.5, 14);
    doc.text('TÍTULO II', 10, 22, null, null, 'center');
    doc.text(subTitles[1], 10, 22.5, null, null, 'center');
    doc.setFontSize(10)
      .setFont('times', 'normal')
      .text(indentThree, 1.5, 23.5);
    // page two
    doc.addPage('a4', 'p');
    doc.addImage('pmdf.png', 'PNG', 1, 0, 3, 3.5);
    doc.setFontSize(12)
      .setLineHeightFactor(1.5)
      .setFont('times', 'bold')
      .text(title, 10, 1.8, 'center')
      .addImage('gdf.png', 'PNG', 16.5, 1, 3, 2);
    doc.setFontSize(10)
      .setFont('times', 'normal')
      .text(indentThree2, 1.5, 4);
    doc.text('TÍTULO III', 10, 7, null, null, 'center');
    doc.text(subTitles[2], 10, 7.5, null, null, 'center');
    doc.text(indentFour, 1.5, 8.5);
    // page three
    doc.addPage('a4', 'p');
    doc.addImage('pmdf.png', 'PNG', 1, 0, 3, 3.5);
    doc.setFontSize(12)
      .setLineHeightFactor(1.5)
      .setFont('times', 'bold')
      .text(title, 10, 1.8, 'center')
      .addImage('gdf.png', 'PNG', 16.5, 1, 3, 2);
    doc.setFontSize(10)
      .setFont('times', 'normal')
      .text(indentFour2, 1.5, 4);
    doc.rect(1.5, 16, 18, 1, 'F');
    doc.setFontSize(12)
      .setFont('times', 'bold')
      .setTextColor(255, 255, 255)
      .text('DECLARAÇÃO', 11, 16.6, null, null, 'center');
    doc.setFontSize(10)
      .setFont('times', 'normal')
      .setTextColor(0, 0, 0)
      .text(declaration, 1.5, 18);
    function save(callback) {
      dateCurrent = new Date().toLocaleString();
      doc.setPage(1);
      doc.setFontSize(12)
        .setFont('times', 'normal')
        .setTextColor(0, 0, 0);
      doc.text(`Nome: ${fields[0].value}`, 1.8, 5);
      doc.text(`Matrícula: ${fields[1].value}`, 14, 5);
      doc.text(`CPF: ${fields[2].value}`, 1.8, 6);
      doc.text(`Posto/Graduação: ${fields[3].value}`, 7, 6);
      doc.text(`Data de Nascimento: ${dateUpdate === 'Invalid Date' ? '' : dateUpdate}`, 14, 6);
      doc.text(`Unidade: ${fields[5].value}`, 1.8, 7);
      doc.text(`Divisão: ${fields[6].value}`, 7, 7);
      doc.text(`Seção: ${fields[7].value}`, 14, 7);
      doc.text(`Telefone: ${fields[8].value}`, 1.8, 8);
      doc.text(`E-mail Pessoal: ${fields[9].value}`, 7, 8);
      doc.setPage(3)
        .setFont('times', 'bold')
        .setFontSize(10)
        .setTextColor(0, 0, 0)
        .text(fields[0].value, 10, 26, null, null, 'center')
        .text(`Mat.: ${fields[1].value}`, 10, 26.5, null, null, 'center')
        .text(`Emissão: ${dateCurrent}`, 17, 28, null, null, 'center');
      doc.save('termo_compromisso.pdf');
      function callback() {
        return fields.forEach(field => {
          field.value = ''
        })
      };
      setTimeout(() => {
        callback()
        location.reload()
      }, 1000)
    }
    return {
      fields,
      save,
      unidades
    };
  },
};
</script>

<style scoped>
  .input {
    @apply w-full bg-blue-50 p-2 rounded focus:outline-none focus:ring focus:border-blue-300;
  }
  .button {
    @apply bg-blue-500 hover:bg-blue-400 py-2 px-8 text-white font-bold rounded;
  }
</style>